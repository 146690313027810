import "./App.css";
import { Route, Routes, Link, Navigate } from "react-router-dom";
import Home from "./pages/Home.js";
import About from "./pages/About.js";
import Book from "./pages/Book.js";
import Speaking from "./pages/Speaking.js";
import Teachers from "./pages/Teachers.js";
import Contact from "./pages/Contact.js";
import Footer from "./Footer.js";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

function App() {
  const navigation = [
    { name: "Home", href: "/" },
    { name: "Book", href: "/book" },
    { name: "About", href: "/about" },
    { name: "Speaking", href: "/speaking" },
    { name: "Teachers", href: "/teachers" },
    { name: "Contact", href: "/contact" },
  ];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div className="App">
      <div class="bg-white">
        <header className="absolute inset-x-0 top-0 z-50">
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link
                to="/"
                class="flex items-center space-x-3 rtl:space-x-reverse"
              >
                <span
                  style={{ fontFamily: "PaintbrushFont" }}
                  className="self-center text-6xl whitespace-nowrap dark:text-white"
                >
                  Gary Freund
                </span>
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-lg font-semibold leading-6 text-gray-900"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                {/* <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
                </a> */}
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
        <main class="min-h-screen flex flex-col justify-between">
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/book" element={<Book />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/speaking" element={<Speaking />}></Route>
            <Route path="/teachers" element={<Teachers />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/*" element={<Navigate to="/" />} />{" "}
          </Routes>
          <script src="https://unpkg.com/flowbite@1.4.5/dist/flowbite.js"></script>
          <Footer />
        </main>
      </div>
    </div>
  );
}

export default App;

import bookCover from "../Images/1715286895.png";
import "./Book.css";
function Book() {
  return (
    <div>
      <div class="lg:bg-hero bg-green-hero mt-24 overflow-hidden lg:px-5">
        <div class="mx-auto grid max-w-7xl grid-cols-1 grid-rows-[auto_1fr] pt-8 md:pt-10 lg:grid-cols-12 lg:px-3 lg:pb-20 lg:pt-10 xl:py-10">
          <div class="relative flex items-end lg:col-span-5 lg:row-span-2">
            <img
              alt="Book Cover"
              fetchpriority="high"
              decoding="async"
              data-nimg="1"
              class={"relative z-10 mx-auto flex w-64 md:w-80 lg:w-auto"}
              src={bookCover}
            />
          </div>
          <div class="relative px-4 sm:px-6 lg:col-span-7 lg:pb-14 lg:pl-14 lg:pr-0 xl:pl-20">
            <br />
            <div class="hidden lg:absolute lg:-top-32 lg:bottom-0 lg:left-[-100vw] lg:right-[-100vw] lg:block "></div>
          </div>
          <div class="relative flex items-center pt-16 lg:col-span-7 lg:bg-transparent lg:pl-16 lg:pt-0 xl:pl-20">
            <div class="mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4 lg:px-0">
              <h1 class="freeman mb-5 text-7xl text-white sm:text-7xl">
                The Financial Compass:
              </h1>
              <h1 class="freeman text-4xl text-slate-100 sm:text-4xl">
                How to Navigate Money With Confidence
              </h1>
              <br />
              <p class="mt-2 text-2xl text-slate-200">
                {/* This book is a comprehensive guide that organizes all of your
                essential day-to-day financial strategies in one place. */}
                #1 New Release in Personal Money Management On Amazon!
              </p>
              <div class="mt-8 flex justify-center gap-4 pb-8">
                {/* <a
                  class="inline-flex justify-center rounded-md border py-[calc(theme(spacing.1)-1px)] px-[calc(theme(spacing.4)-1px)] text-base font-semibold tracking-tight focus:outline-none hover:bg-green-200 text-green-600  bg-blue-50 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 active:text-blue-600/70 disabled:opacity-40"
                  variant="outline"
                  href="https://a.co/d/9ECcgy7"
                >
                  Amazon
                </a> */}
                <a
                  href="https://www.amazon.com/Financial-Compass-Navigate-Money-Confidence-ebook/dp/B0D7QW8SPX/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ border: "none", textDecoration: "none" }}
                >
                  <img
                    src="https://www.niftybuttons.com/amazon/amazon-button2.png"
                    alt="Amazon Button"
                  />
                </a>
                <a
                  href="https://bookshop.org/p/books/the-financial-compass-gary-freund/21606587?ean=9781957917443"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ border: "none", textDecoration: "none" }}
                >
                  <img
                    src="https://cdn.prod.website-files.com/6267f35934aa8b1795cf1a9f/63e3d997b5c82400a64e1249_BUY-BS-BUTTON-F2-p-500.png"
                    alt="Bookshop Button"
                    style={{ maxHeight: "50px" }}
                  />
                </a>
                <a
                  href="https://www.barnesandnoble.com/w/the-financial-compass-gary-freund/1145946975;jsessionid=CC4752857BC8D6A5B82D646C1752A3A7.prodny_store01-atgap01?ean=9781957917443"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ border: "none", textDecoration: "none" }}
                >
                  <img
                    src="https://images.squarespace-cdn.com/content/v1/5b3bc5e6f407b493fda3617d/1530665417371-P4ANA7Q76PFBRQTX8JPA/barnes-and-noble-buy-button.png"
                    alt="Barns Button"
                    style={{ maxHeight: "50px" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div class="lg:columns-2 md:columns-2 space-y-10 mx-auto lg:max-w-7xl">
        <div class=" text-center text-left">
          <div class="flex justify-center justify-start">
            <div class="flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
            </div>
          </div>
          <blockquote class="mt-2">
            <p class="font-display text-xl font-medium text-slate-900">
              “This book is essential for college students and recent graduates
              and offers 40 action steps for making informed financial
              decisions. I highly recommend it.”
            </p>
          </blockquote>
          <figcaption class="mt-2 text-sm text-slate-500">
            <strong class="font-semibold text-green-600 before:content-['—_']">
              Mun Choi
            </strong>
            , President of the University of Missouri System
          </figcaption>
        </div>
        <figure class=" text-center text-left">
          <div class="flex justify-center justify-start">
            <div class="flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
            </div>
          </div>
          <blockquote class="mt-2">
            <p class="font-display text-xl font-medium text-slate-900">
              “As a former Business Education Teacher, I appreciate how Gary
              simplifies complex topics. The practical advice and real-life
              examples empower readers to confidently take control of their
              financial futures.”
            </p>
          </blockquote>
          <figcaption class="mt-2 text-sm text-slate-500">
            <strong class="font-semibold text-green-600 before:content-['—_']">
              Mike Alther
            </strong>
            , Principal at Hoffman Estates High School
          </figcaption>
        </figure>
      </div>
      <br />
      <br />

      <div class="mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4 lg:max-w-4xl lg:px-12 text-lg tracking-tight text-slate-700">
        <p class="mt-4 text-left">
          The compass is a symbol of guidance and direction. It reminds us that
          we can chart our own path in life. Consider this book your life's
          financial compass - a comprehensive guide that organizes all of your
          essential day-to-day financial strategies in one place! In this
          must-read book, you'll learn how to:
        </p>
        <ul class="mt-4 space-y-3">
          <li class="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.25em"
              width="1.25em"
              viewBox="0 0 448 512"
            >
              <path
                fill="#31776c"
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
            <span class="ml-4">Build wealth through investing</span>
          </li>
          <li class="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.25em"
              width="1.25em"
              viewBox="0 0 448 512"
            >
              <path
                fill="#31776c"
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
            <span class="ml-4">
              Stretch your earnings and acheive your goals
            </span>
          </li>
          <li class="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.25em"
              width="1.25em"
              viewBox="0 0 448 512"
            >
              <path
                fill="#31776c"
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
            <span class="ml-4">Establish and maintain good credit</span>
          </li>
          <li class="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.25em"
              width="1.25em"
              viewBox="0 0 448 512"
            >
              <path
                fill="#31776c"
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
            <span class="ml-4">Get a great deal every time you buy a car</span>
          </li>
          <li class="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.25em"
              width="1.25em"
              viewBox="0 0 448 512"
            >
              <path
                fill="#31776c"
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
            <span class="ml-4">Save money when you rent an apartment</span>
          </li>
          <li class="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.25em"
              width="1.25em"
              viewBox="0 0 448 512"
            >
              <path
                fill="#31776c"
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
            <span class="ml-4">
              Save significant money through home ownership
            </span>
          </li>
          <li class="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.25em"
              width="1.25em"
              viewBox="0 0 448 512"
            >
              <path
                fill="#31776c"
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
            <span class="ml-4">
              Select the right mortgage and save tens of thousands of dollars
            </span>
          </li>
          <li class="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.25em"
              width="1.25em"
              viewBox="0 0 448 512"
            >
              <path
                fill="#31776c"
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
            <span class="ml-4">
              Refinance your home when the rates are the lowest
            </span>
          </li>
          <li class="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.25em"
              width="1.25em"
              viewBox="0 0 448 512"
            >
              <path
                fill="#31776c"
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
            <span class="ml-4">
              Save money on your auto, home, health, and life insurance
            </span>
          </li>
          <li class="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.25em"
              width="1.25em"
              viewBox="0 0 448 512"
            >
              <path
                fill="#31776c"
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
            <span class="ml-4">
              Secure your children's financial well-being
            </span>
          </li>
          <li class="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.25em"
              width="1.25em"
              viewBox="0 0 448 512"
            >
              <path
                fill="#31776c"
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
            <span class="ml-4">Retire with financial freedom</span>
          </li>
        </ul>
        <p class="mt-4 text-left">. . . and so much more!</p>
        <p class="mt-4 text-left">
          Written in a no-nonsense, straightforward way that adults of all ages
          can understand, <i>The Financial Compass</i> will teach you the whys,
          whats, and hows of making smart financial decisions that will
          positively impact the rest of your life.
        </p>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default Book;

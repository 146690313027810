import { Link } from "react-router-dom";
import HomeImage from "../Images/Profile Picture.png";
// import compassImage from "../Images/compass illustration5.png";

function Home() {
  return (
    <div className="relative isolate pt-14">
      {/* <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#afd8ff] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div> */}

      {/* <div className="mx-auto py-32 sm:py-48 lg:py-56 flex flex-row">
        <div className="text-center w-2/3">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            I’M ON A MISSION TO HELP YOU MAKE SOUND{" "}
            <font color="#059669">FINANCIAL DECISIONS</font> THAT GUIDE YOU TO A
            DEEPER MORE MEANINGFUL LIFE.
          </h1>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/book"
              className="rounded-md bg-emerald-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              My Book
            </Link>
            <Link
              to="/about"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              About Me <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
        <img class="w-1/2" alt="Profile pic" src={HomeImage} />
      </div>
    */}
      <section class="bg-white dark:bg-gray-900 mt-10">
        <div class="grid max-w-screen-xl px-4 pt-8 mx-auto lg:gap-8 xl:gap-0 lg:pt-16 lg:grid-cols-12">
          <div class="mr-auto place-self-center lg:col-span-6">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 md:text-4xl xl:text-5xl">
              I'M ON A MISSION TO EMPOWER YOU TO MAKE{" "}
              <font color="#059669">SMART, INFORMED FINANCIAL DECISIONS</font>{" "}
              THAT LEAD TO A MORE MEANINGFUL AND FULFILLING LIFE.
            </h1>
            <div className="my-10 flex items-center justify-center gap-x-6">
              <Link
                to="/book"
                className="rounded-md bg-emerald-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                My Book
              </Link>
              <Link
                to="/about"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                About Me <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
          <div class="hidden lg:mt-0 lg:col-span-6 lg:flex">
            <img src={HomeImage} alt="mockup" />
          </div>
        </div>
      </section>
      <div className="bg-emerald-600 h-12 flex items-center justify-center">
        {/* <div className="bg-emerald-600 h-50 w-50 py-5 px-5 rounded-full flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="40"
            width="40"
            viewBox="0 0 512 512"
          >
            <path
              fill="#ffffff"
              d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm306.7 69.1L162.4 380.6c-19.4 7.5-38.5-11.6-31-31l55.5-144.3c3.3-8.5 9.9-15.1 18.4-18.4l144.3-55.5c19.4-7.5 38.5 11.6 31 31L325.1 306.7c-3.2 8.5-9.9 15.1-18.4 18.4zM288 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
            />
          </svg>
          <img src={compassImage} alt="compass" height={40} width={40} />
        </div> */}
      </div>
      <div className="bg-gray-200">
        <div className="flex flex-wrap gap-8 justify-center p-10">
          <div class="relative flex flex-col mt-6 text-gray-700 bg-white shadow-lg bg-clip-border rounded-xl w-96">
            <div class="relative h-56 mx-4 -mt-6 overflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40">
              <img
                class="object-cover w-full h-full"
                src="https://www.stockvault.net/data/2009/05/08/108803/preview16.jpg"
                alt="money"
              />
            </div>
            <div class="p-6">
              <h5 class="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                The Power of Early Investing
              </h5>
              <h6 class="block mb-2 font-sans text-md antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                Why Your 20s Are Key to a Secure Retirement
              </h6>
              <p class="block font-sans text-base antialiased font-light leading-relaxed text-inherit">
                Discover how starting to invest in your 20s can turn small
                monthly contributions into a secure retirement. Learn why even a
                few years can make a huge difference in your financial future
                and how you can maximize your wealth by starting early.
              </p>
            </div>
            <div class="p-6 pt-0">
              <button
                class="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                type="button"
              >
                Coming soon..
              </button>
            </div>
          </div>

          <div class="relative flex flex-col mt-6 text-gray-700 bg-white shadow-lg bg-clip-border rounded-xl w-96">
            <div class="relative h-56 mx-4 -mt-6 overflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40">
              <img
                class="object-cover w-full h-full"
                src="https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="house"
              />
            </div>
            <div class="p-6">
              <h5 class="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                The Real Impact of Your Credit Score
              </h5>
              <h6 class="block mb-2 font-sans text-md antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                Why It Matters More Than You Think
              </h6>
              <p class="block font-sans text-base antialiased font-light leading-relaxed text-inherit">
                A good credit score can save you thousands on home and car
                loans, turning costly interest payments into significant
                savings. Learn how a few credit score points can make all the
                difference.
              </p>
            </div>
            <div class="p-6 pt-0">
              <button
                class="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                type="button"
              >
                Coming soon..
              </button>
            </div>
          </div>

          <div class="relative flex flex-col mt-6 text-gray-700 bg-white shadow-lg bg-clip-border rounded-xl w-96">
            <div class="relative h-56 mx-4 -mt-6 overflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40">
              <img
                class="object-cover w-full h-full"
                src="https://images.pexels.com/photos/164634/pexels-photo-164634.jpeg"
                alt="stock ticker"
              />
            </div>
            <div class="p-6">
              <h5 class="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                How Two Buyers Can Pay Drastically Different Prices at the Same
                Car Dealership
              </h5>
              <p class="block font-sans text-base antialiased font-light leading-relaxed text-inherit">
                In the car business, two buyers can pay drastically different
                prices for the same car on the same day. Learn how knowing the
                car's value and what you should pay can save you thousands.
              </p>
            </div>
            <div class="p-6 pt-0">
              <button
                class="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                type="button"
              >
                Coming soon..
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

import TeacherLecture from "../Images/TeachersHeaderCopy.jpg";

function Teachers() {
  return (
    <div className="flex flex-col justify-center">
      <div className="mt-24 relative w-full h-fit">
        <img
          src={TeacherLecture}
          alt="Banner"
          className="object-scale-down mx-auto "
        />
        {/* <div
          className="absolute w-full h-full flex items-center justify-center visible lg:invisible"
          style={{ marginTop: "-500px" }}
        >
          <h1 className="oswald text-white text-8xl font-bold">Teachers</h1>
        </div> */}
      </div>
      <div class="mx-auto my-8 flex flex-col gap-y-5 md:w-1/2 w-5/6 justify-center">
        <p class="text-xl mt-4 text-left">Dear Teachers,</p>
        <p class="text-xl  text-left">
          I had hundreds of students ask me to write this book. My journey into
          education was driven by the desire to positively impact young lives,
          and writing this book allows me to continue that lifelong mission.
        </p>
        <p class="text-xl text-left">
          I’m a retired Business Education Teacher who always searched for
          high-quality resources to enhance my instruction. I’m confident that
          <i> The Financial Compass</i> will prove to be an invaluable asset for
          you and your students. Here are three compelling ways you can
          incorporate the book:
        </p>
        <ol className="text-xl  my-2 text-left list-decimal list-outside space-y-6">
          <li>
            Utilize the abundance of examples to enrich your classroom lessons.
          </li>
          <li>
            Implement the wealth of practical information in your own life.
          </li>
          <li>
            Have your high school adopt <i>The Financial Compass</i> as a
            supplemental Consumer or Personal Finance course resource.{" "}
          </li>
        </ol>
        <p class="text-xl  text-left">
          If you are interested in incorporating the book into your curriculum,
          please don’t hesitate to reach out to me. I’m more than happy to
          collaborate on curricular ideas and provide supplemental materials
          such as activities, rubrics, and test questions. Thank you for
          considering <i>The Financial Compass</i>, and here’s to making every
          day in the classroom a great one!
        </p>
        <p class="text-xl  text-left">Gary</p>
      </div>
    </div>
  );
}

export default Teachers;
